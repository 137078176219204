import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
	{
		name: 'castpoint',
		path: '/setups/:idSetup/castpoint/:idCastpoint',
		component: () => import('@/views/BulletinPoint'),
	},
	{
		name: 'zone',
		path: '/setups/:idSetup/zone/:idZone',
		component: () => import('@/views/BulletinZone'),
	}
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

export default router;
