import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import moment from 'moment-timezone';
import i18n from './i18n'
// import VueMoment from 'vue-moment';
moment.locale('fr')
moment.tz.setDefault('America/Montreal');
require('dotenv').config();
// Vue.use(VueMoment);
Vue.prototype.$moment = moment

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app');
